
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:500);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";


* {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  //overflow-y: hidden;
  font-size: 12px;
}

@media (max-width: 1025px) {
  body {
    overflow-y: scroll;
  }
}

//Fix strange behavior in bootstrap where rows overflowed
//*********************
.row {
  margin: 0 auto;
}
//*********************

.navbar {
  margin-bottom: 3px;
}


//Welcome Page Styling
.welcomeInfo {
  font-size: 24px;
}

.backgroundAttribution {
  position: absolute;
  bottom: 0;
  font-size: 8px;
}

.greeting {
  position: fixed;
  top: 0;
  z-index: 2;
  width: 100%;
  box-sizing: border-box;
  font-family: Raleway, 'Antic Slab';
  background: #fff;
  margin-top: 0px;
  padding: 7px 20px 2px;
  -webkit-box-shadow: 0 2px 4px #000;
  -moz-box-shadow: 0 2px 4px #000;
  box-shadow: 0 2px 4px #000;
  height: 47px;
}

.greeting h1 {
  font-size: 42px;
  margin: 5px 10px;
}

.mainLogo {
  height: 42px;
  margin-top: -4px;
}

.searchBar {

  color: #B9461D;
  font-weight: bold;
  outline: hidden;
}

.conferenceSearch {
  margin-bottom: 3vh;
}

.siteWelcomeHeading {
  height: 100vh;
  opacity: .1;
}

.siteWelcome {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-top: 65px;
  margin-bottom: 20px;
}

.mainDescription {
  padding: 0;
  text-align: center;
  color: #fff;
  font-size: 2.1em;
  font-weight: bold;
  text-shadow: 0px 2px #333, -1px 0 #666, 1px 0px #666;
}

.newConference {
  text-shadow: none;
}
/*
.backButton {
  position: absolute;
  bottom: 1%;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: #fff;
  border-radius: 50%;
  height: 90px;
  width: 90px;
  text-align: center;
  font-size: 2em;
  box-shadow: 0 2px 6px 1px #000;
  text-align: center;
  z-index: 3;
}
*/
.backButton:hover, .aboutLeft:hover, .aboutRight:hover {
  color: #5bc0de;
}


@keyframes arrowBounce {
  0% {padding-top: 0px;}
  100% {padding-top: 10px;}
}

.aboutPageContainer {
  padding-top: 5%;
  background: linear-gradient(rgba(0,0,0,0) 0%, rgba(0,0,0,.8) 80%, rgba(0,0,0,1) 90%);
  opacity: .1;
  margin: 0 auto;
  padding: 0;
  color: #fff;
  font-size: 1.6em;
  font-weight: light;
  text-shadow: 0px 3px #333, -1px 0px #666, 1px 0px #666;
  padding-bottom: 35px;
}

.aboutPage {
  padding-top: 5vh;
}

.aboutPage p, .aboutPage li {
  font-size: 1.5em;
}

.aboutLeft {
  color: #fff;
  position: absolute;
  top: 50vh;
  left: 5%;
  padding-top: 25px;
  border-radius: 0;
  height: 80px;
  width: 60px;
  z-index: 2;
  margin-left: 0;
  font-size: 3em;
}

.aboutRight {
  position: absolute;
  color: #fff;
  top: 50vh;
  right: 5%;
  padding-top: 25px;
  height: 80px;
  width: 60px;
  z-index: 2;
  margin-right: 0;
  font-size: 3em;
}

.welcomeMessage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 8px;
  color: #fff;
  margin: 0 auto;
  text-align: center;
  opacity: .1;
  font-family: Raleway;
  font-size: 4rem;
  font-weight: bold;
  text-shadow: 0px 3px #333, -1px 0px #666, 1px 0px #666;
}

.welcomeIcon {
  border: 1px solid #fff;
  background: rgba(256,256,256, .4);
  border-radius: 5px;
  padding: 2px;
  margin-top: -8px;
  height: 80px;
}

.aboutList li {
  font-size: 1em;
  list-style: square;
}


.footer {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 0 30px;
  background: #fff;
  height: 30px;
  box-shadow: 0 -1px 2px #000;
}

.footer-large {
  margin-top: 5px;
  font-size: 1.3em;
  font-weight: bold;
}

.footer a {
  color: #333;
}

.copyright {
  text-align: left;
}

.credits {
  text-align: right;
}

.listall-small {
  display: none;
}

.listall-large {
  display: inline-block;
}

.contact-small {
  display: none;
}

@media only screen and (max-width: 480px) {

  .contact-large {
    display: none;
  }

  .listall-small {
    display: inline-block;
  }

  .listall-large {
    display: none;
  }

  .aboutPageContainer {
    padding: 0 4vh;
  }

  .aboutPage {
    padding: 0;
  }

  .aboutPage h2 {
    font-size: 16px;
  }

  .welcomeDesc {
    margin: 5px 0;
    font-size: 14px;
    height: 45px;
  }

  .footer {
    height: 60px;
  }

  .copyright {
    text-align: center;
  }

  .credits {
    text-align: center;
  }

  .welcomeMessage {
    font-size: 20px;
  }

  .aboutPageItem {
    font-size: .7em;
  }

  .welcomeIcon {
    height: 45px;
    margin: 5px 0;
  }

  .mainLogo {
    position: fixed;
    top: 8px;
    left: 5px;
    height: 38px;

  }

  .showConferences {
    position: fixed;
    top: 5px;
    right: 7px;
    width: 194px;
    height: 35px;
    padding-top: 7px;
    text-align: center;
  }

  .backButton {
    position: fixed;
    right: 5px;
  }

  .aboutLeft {
    position: absolute;
    top: 50vh;
    left: 0;
    padding-top: 15px;
    height: 80px;
    min-width: 30px;
    width: 5vh;
    z-index: 2;
    margin-left: 0;
    font-size: 2em;
    box-shadow: 0 2px 5px #000;
    border-radius: 0;
  }

  .aboutRight {
    position: absolute;
    top: 50vh;
    right: 0;
    position: absolute;
    padding-top: 15px;
    height: 80px;
    min-width: 30px;
    width: 5vh;
    z-index: 2;
    margin-right: 0;
    font-size: 2em;
    box-shadow: 0 2px 5px #000;
    border-radius: 0;
  }
}

.searchOutput {
  display: none;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  background: white;
  border: 1px solid #aaa;
  z-index: 4;
  -webkit-box-shadow: 0 2px 4px #000;
  -moz-box-shadow: 0 2px 4px #000;
  box-shadow: 0 2px 4px #000;
}

.mainContainer {
  background: #fff;
  margin-top: 40px;
}

.outputRow {
  
  font-size: 12px;
}

.outputRow:nth-of-type(2n+0) {
  background: #eee;
  font-size: 1.1em;
}

.outputRow:hover {
  background: #de9d60;
  color: white;
}

.searchOutputName, .searchOutputStart {
  display: inline-block;
  padding: 8px 0 8px 10px;
  text-decoration: none;
}

.searchOutputName {
  width: 80%;
}

.allConferences {
  padding-top: 8vh;
}

.conferenceListWrapper {
  max-height: 50vh;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 2px solid #333;
  border-radius: 3px;
}

.conferenceList {
  font-size: 1em;
  width: 100%;
  margin: 0 auto;
  overflow-y: scroll;
}

th, td, .headName, .headDate {
  padding-left: 8px;
}

.tableInfo {
  font-size: 1.1em;
  font-weight: bold;
  background: #fff;
  border: 2px solid #333;
  border-bottom: none;
  margin-top: 15px;
}

.headName, .headDate {
  display: inline-block;
}

.headName {
  width: 61%;
}

.tableName {
  width: 60%;
}

.tableDate, .headDate {
  text-align: center;
  width: 18%;
}

.conferenceTableHeading {
  height: 30px;
  width: 100%;
  border-bottom: 2px solid #333;
}

.conferenceTableRow {
  font-size: 1.1em;
  color: #666;
  height: 20px;
  background: #fff;
}

.conferenceTableRow a {
  color: #666;
}

.conferenceTableRow:nth-of-type(2n+0) {
  background: #ebebeb;
}

.conferenceTableRow:hover {
  background: #F15A24;
  //background: #5bc0de;
  color: #fff;
  font-weight: 600;
}

.loading {
  display: none;
  height: 40px;
}


//********************

//Registration page styling
//*********************
.advisor {
  display: none;
}

.conferencepw {
  background: #fdd;
  padding: 10px;
  margin: 0;
  border-radius: 8px;
}

.conferencepw input {
  margin-top: 5px;
}

.stayVisible {
  display: block;
}

.conferenceNameOutput {
  box-sizing: border-box;
  height: 0;
  opacity: .05;
  font-size: 1.1em;
  text-align: center;
  border-radius: 8px;
  border-left: 1px solid #666;
  border-right: 1px solid #666;
}

.isTaken {
  font-weight: bold;
  font-size: 1.2em;
}
//*********************
button {
  outline: none;
}

.logoutButton {
  margin-top: 7px;
}

.warning {
  color: red;
}

.sortSelect, .searchGroup {
  height: 20px;
}

.searchGroup {
  padding-top: 3px;
}

.settings {
  overflow-y: auto;
  padding-bottom: 200px;
}

.adminUserSearch {
}

.adminSetting, .authorPanelConfirm {
  margin: 8px;
  padding: 8px;
  border: 1px solid $paper-border;
  border-radius: 6px;
  background: white;
}

.conferenceSettingWrapper {
  padding: 8px;
}

.conferenceSettingWrapper:last-of-type {
  margin-bottom: 100px;
}

.adminConferenceNote {

}

.conferenceSetting {
  border: 1px solid $paper-border;
  padding: 8px;
  border-radius: 6px;
  background: white;
}

.conferenceSetting input[type="text"], .conferenceSetting input[type="date"], .adminSetting input {
  height: 24px;
}

.conferencePassword {
  min-width: 47%;
}

.currentDate {
  color: green;
}

.adminSetting:last-of-type {
    margin-bottom: 200px;
}

.adminInstructionIcon {
  display: inline-block;
  color: #ff3333;
  animation: arrowMove 2s;
  animation-iteration-count: 60;
}

@keyframes arrowMove {
  0% {margin-left: 15px;}
  100% {margin-left: 0px;}
}

.resultCount {
  display: inline-block;
  margin-top: 8px;
  margin-left: -10px;
  font-size: 1.2em;
  font-weight: bold;
}

.paperListOption {
  padding: 3px 5px 0;
}

.pagination {
  margin: 0;
  padding: 0;
  color: #777;
}

.pagination {
  border-radius: 5px;
  display: inline-block;
  box-sizing: border-box;
  border-radius: 5px;
}

.pagination .active {
  background: #85C1E9;
}

.pagination li {
  display: inline-block;
  box-sizing: border-box;
  height: 30px;
  width: 30px;
  border-top: 1px solid #aaa;
  border-bottom: 1px solid #aaa;
  border-left: 1px solid #aaa;
  text-align: center;
  padding: 2px 0;
  font-size: 1.1em;
  font-weight: bold;
}

.pagination li:first-of-type {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  background: #D6EAF8;
}

.pagination li:last-of-type {
    border-right: 1px solid #aaa;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    background: #D6EAF8;
}

.pagination .active {
  color: white;
  font-weight: bold;
}

.noPapersToList {
  height: 200px;
  padding: 12px 25px;
  border: 1px solid $paper-border;
}

.outputPanelAttribute {
  padding: 2px 0;
}

.attributeLabel {
  font-weight: bold;
  font-size: 1.2em;
  text-align: center;
}

.paper, .panelPaper, .panel {
  border: 1px solid $paper-border;
  margin-top: 0;
  margin-bottom: 20px;
  padding: 4px;
  border-radius: 3px;
  box-shadow: 0 4px 10px #000;
}

.panel {
  box-sizing: border-box;
  padding: 8px;
  margin-bottom: 50px;
  border: 2px solid #aaa;
}

.panelTitle {
  font-size: 1.4em;
  font-weight: bold;
}

.addPanel, .panelHelp  {
  margin-top: 8px;
}

.searchBarMargin {
  margin-left: 10px;
}

.panelPaperList, .panelList {
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 12px 200px 0;
}

.spacer {
  //margin-bottom: 500px;
}

.viewPanelButton {
  display: inline-block;
}

.panelPaper {
  padding: 6px 0 0 0;
  background: #fff;
  margin-bottom: 50px;
  border: 2px solid #aaa;
}

.panelPaper:last-of-type {
  margin-bottom: 400px;
}

.panelSettingLabel {
  padding-top: 3px;
}

.panelSetting {
  margin: 2px 0;
}

.panelSlot {
  min-height: 35px;
  border: 1px solid $paper-border;
}

.filledSlot {
  padding: 5px 0;
}

.sendConfirmationEmail, .overrideConfirmation {
  margin-bottom: 8px;
}

.confirmationEmailSent {
  background: #bbeeee;
}

.emailOne {
  font-weight: bold;
}

.panelConfirmed {
  background: #cfc;
}

.openSlot {
  background: $light-background;
}

.newPanel {
  box-sizing: border-box;
  margin-left: 0 auto;
  padding: 20px;
}

.panelInput {
  height: 22px;
}

.panelSectionHeading {
  margin: 2px 4px;
  font-size: 1.05em;
}

.setDiscussant, .setChair {
  margin-top: -32px;
}

.removeFromPanel {
  background: none;
  color: #b22;
  border: none;
  font-size: 26px;
  margin-left: -10px;
}

.paper:last-of-type {
  margin-bottom: 100px;
}

.addFilterTag, .removeFilterTag {
  margin: 2px;
  display: inline-block;
}

.filterOutputRow {
  padding: 0;
  margin: 0;
}

.filterPaperCount {
  color: darkgreen;
  font-weight: bold;
  font-size: 1.2em;
  margin-top: 8px;
}

.removeFilterTagSymbol {
  margin-left: 4px;
  font-size: 1.1em;
}

.requestDeletion {
  border-radius: 0;
}

.authorhead {
  box-sizing: border-box;
  background: $heading-background;
  margin: -5px 0 0 0;
  min-height: 40px;
  padding: 15px 0 0 0;
}

.authorbutton {
  outline: none;
  padding: 5px;
  font-size: $lg-button-font-size;
  box-shadow: 0px 1px 3px #000;
  margin-bottom: 10px;
}

.authorBanner {
  height: 40px;
  padding: 18px 0 0 0 ;
}

.authorBanner h3 {
  margin: 0 0 3px 0;
}

.authorInstructionsBox {
  box-sizing: border-box;
  display: none;
  padding: 8px 0;
  background: $panel-default-heading-bg;
  height: 180px;
  border-bottom: 3px solid #bbb;
}

.authorInstructions {
  display: none;
}

.newPaperDialog, .help, .filterOptions, .panelOptionsWrapper {
  background: $dialog-background;
  display: none;
  width: 750px;
  padding: 0;
  position: absolute;
  top: 55px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  z-index: 5;
  border-radius: 4px;
  box-shadow: 0 8px 20px #000;
  border: 1px solid $dialog-border;
}

.help, .filterOptions, .panelOptionsWrapper {
  background: #fff;
  padding: 15px;
  line-height: 1.3em;
  overflow-y: auto;
  max-height: 700px;
  margin-top: -100px;
  margin-bottom: 100px;
  top: 110px;
}

.panelOptionsWrapper {
  max-height: 800px;
  margin-bottom: 200px;
  padding: 0 15px 15px;
}

.searchLabel {
  margin-bottom: -6px;
  margin-top: -2px;
}

.adminPanelPaperSearch {
  margin-top: 8px;
}

.newFile {
  height: 55px;
}

.newUploadInput {
  display: inline-block;
  width: 70%;
}

//These are added to the view if there is a validation error
//when a new paper is submitted
//******************************
.newPaperDialogError {
  display: block;
}

.dimmableError {
  opacity: .2;
  background: #000;
}
//******************************


.newPaperFormWrapper {
  width: 730px;
  border: 1px solid #ccc;
  margin: 10px;
  padding: 8px;
  float: left;
}

.tagOptionWrapper, .coauthorListWrapper {
  display: none;
  box-sizing: border-box;
  padding: 10px;
}

.tagOptionList, .coauthorList {
  max-height: 450px;
  overflow-y: scroll;
  margin-bottom: 10px;
  border-radius: 6px;
}

.coauthorList {
  overflow-y: auto;
}

.tagOptionHeader button {
  font-size: 1.4em;
}

.tagWarning {
  color: red;
}

.tagName {
  float: left;
  margin-right: 5px;
}

.deleteTag {
  display: inline-block;
  float: right;
  color: red;
  margin-left: 10px;
  font-size: 1.3em;
}

.addTagButton {
  float: right;
}

.tagOption {
  border: 1px solid #555;
  background: $light-background;
  margin: 5px auto;
  padding: 8px;
  font-weight: bold;
  font-size: 1.3em;
  border-radius: 6px;
}

.tagOptionTag {
  display: inline-block;
  margin-top: 4px;
}

.addTags {
  background: $light-background;
  border-bottom: $darker-bg;
}

.screenSizeWarning {
  padding: 0;
  text-align: center;
}

.tagsExample, .tag, .miniTag {
  display: inline-block;
  border: 1px solid #666;
  border-radius: 3px;
  background: $tag-bg;
  padding: 1px 8px;
  margin: 3px 5px 3px 5px;
  color: #444;
}

.miniTag {
  border: 1px solid #aaa;
  font-size: .85em;
  padding: 0px 6px;
  margin: 1px 2px 3px;
}

.newTag {
  padding: 0;
}

.tagList {
  background: $darker-bg;
}

//Author paper boxes
.authorPaperAttribute {
  border-bottom: 1px solid $paper-border;
  padding: 4px 0 4px 0;
}


.uploadWrapper {
  background: $light-background;
  border: 1px solid $heading-background;
  padding: 3px;
  margin: 6px 0 10px 10px;
}


//Admim pages styling

.sendNotificationWindowWrapper {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.8);
  overflow: auto;
}

.sendNotificationWindow, .messagesSentWrapper {
  display: none;
  position: fixed;
  top: 65px;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 15px 20px 0;
  border: 1px solid #777;
  border-radius: 5px;
  background: white;
  width: 1000px;
  height: 490px;
  z-index: 3;
}

.notificationOptionWrapper {
  height: 40px;
  padding: 0;
}


//ADMIN MESSAGING POPUP
.messageUserSelect {
  display: none;
}

.sendMessageArea {
  height: 270px;
  border-radius: 5px;
}

.messageUserListWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  overflow-y: auto;
}

.messageUserListTable {
  width: 100%;
}

.messageUserListHead {
  display: block;
  width: 100%;
  font-size: 14px;
}

.messageUserList {
  display: none;
  position: fixed;
  top: 65px;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 10px 20px 10px;
  border: 1px solid #777;
  border-radius: 5px;
  background: white;
  width: 1000px;
  height: 490px;
  z-index: 4;
  overflow: auto;
}

.messageUserListOutput {
  display: block;
  width: 100%;
  overflow-y: scroll;
  height: 395px;
}

.messageUserListOutput tr {
  width: 100%;
}

.messageUserListOutput tr:nth-of-type(2n + 1) {
  background: #eee;
}

.messageUserListOutput tr:hover {
  background: #ddf;
}

.messageUserListFirstName {
  width: 140px;
}

.messageUserListLastName {
  width: 140px;
}

.messageUserListAffiliation {
  width: 430px;
}

.messageUserListProposals {
  width: 140px;
}

.messageUserListSelect {
  width: 80px;
}

.messageUserListLoading {
  height: 30px;
  display: none;
}

.messageInfo {
  display: inline-block;
  margin-left: 20px;
  font-size: 16px;
  font-weight: bold;
}

.messageWindowHeading {
  margin-top: 6px;
  margin-bottom: 12px;
}

.messageUploadButtonWarpper {
  margin-top: 6px;
  margin-bottom: 12px;
}

.messageFileInfo, .messageFileWarning {
  margin-left: 20px;
}

.messageWarning, .messageSuccess {
  font-size: 14px;
  display: inline-block;
  margin-left: 30px;
}

.messageWarning {
  color: red;
}

.messageSuccess {
  color: green;
}

.messagesSentWrapper {
  display: none;
  z-index: 5;
}

.adminMessageList {
  display: block;
  overflow-y: scroll;
  height: 365px;
}

.adminMessageLabel {
  display: inline-block;
  width: 100px;
  margin: 3px 0;
  font-weight: bold;
}

.adminSentToList {
  margin-left: 100px;
}

.authorMessageCountBell {
  display: inline-block;
  font-size: 32px;
  color: #7f7f7f;
  margin-top: 8px;
  text-outline: 2px #5ff;
}

.authorMessageCountBell:hover {
  color: #666;
  cursor: hand;
  cursor: pointer;
}

.authorMessageCounter {
  position: relative;
  left: -8px;
  top: 0px;
  box-sizing: border-box;
  display: inline-block;
  height: 22px;
  width: 22px;
  background: #5bc0de;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: white;
  z-index: -1;
}

.authorHasMessages {
  background: orange;
}

.authorMessagesBackground {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255,255,255,.8);
  display: none;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.authorMessagesWrapper {
  background: white;
  position: absolute;
  top: 65px;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 490px;
  width: 900px;
  padding: 20px;
  box-shadow: 0px 5px 8px;
  border: 1px solid #777;
  border-radius: 5px;
  display: none;
}

.authorMessageList {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 10px;
}

.authorMessageLabel {
  display: inline-block;
  font-weight: bold;
  width: 100px;
  margin-bottom: 3px;
}

//ADMIN SIDEBAR
.sidebar {
  border-right: 1px solid $dark-border;
  margin-bottom: 80px;
}

.sidebarTitle, .sidebarLink {
  text-decoration: none;
  list-style: none;
  margin-right: 0;
}

.sidebarLink {
  padding-top: 5px;
  margin-left: -10px;
}

.sidebarLink a {
  color: #666;
}

.sidebarLink:hover {
  color: #f33;
  text-decoration: underline;
}

.count, .miniCount {
  background: $count-bg;
  color: white;
  display: inline-block;
  border-radius: 3px;
  padding: 2px 5px;
  margin: 3px 0 3px -12px;
  min-width: 30px;
  text-align: center;
}

@media only screen and (max-width: 1100px) {
  .count {
    margin-top: 2px;
    margin-left: 0;
    width: 25px;
  }
}

.countAlt {
  background: $count-alt-bg;
}

.miniCount {
  padding: 1px 3px;
  min-width: 20px;
  margin: 0 5px 0 -5px;
}

.active a {
  font-weight: bold;
  color: #44c;
}

.divider {
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid $dark-border;
}

.lightDivider {
  border-bottom: 1px solid $light-border;
}

.adminPaper {
  opacity: .20;
  margin: 15px 5px 60px 5px;
}

.adminPaperList {
  margin-right: 3%;
  padding-right: 1%;
  max-height: 700px;
  overflow-y: scroll;
}

.adminTagList {
  padding: 18px;
  overflow-y: auto;
}

.tagWrapper:last-of-type {
    margin-bottom: 150px;
}

.spacer {
  margin-top: 8px;
}

.adminOptions {
  padding: 5px 0;
}

.noteStatus {
  color: green;
  font-weight: bold;
  font-size: 1.3em;
}

.rightButton {
  float: right;
  margin-right: 15px;
}

.fadeIn {
  opacity: .1;
  animation: fadeIn .7s forwards;
}

.adminOptionsLabel {
  margin-top: 8px;
}

.confirmDeletion {
  background: red;
  color: white;
  font-weight: bold;
}

.confirmDeletion:hover {
  color: white;
}

.addTagFormWrapper {
  background: $light-background;
  padding: 10px 0 10px 20px;
  min-height: 50px;
  box-shadow: 0 1px 2px #000;
}

.addTagText {
  min-width: 380px;
  max-width: 380px;
  float: left;
}



.tagWrapper {
  opacity: 1;
  background: $light-background;
  margin: 6px 2%;
  width: 45%;
  float: left;
  padding: 5px;
  font-size: 1.2em;
  font-weight: bold;
  box-shadow: 0 1px 4px #000;
}

.tagDelete, .authorTagDelete, .coauthorDelete {
    background: none;
    opacity: .6;
    border: none;
    color: red;
    height: 30px;
    font-size: 1.4em;
    float: right;
}

.tagDelete:hover, .authorTagDelete:hover {
  opacity: 1;
}

//Admin User Page
.userList {
  overflow-y: scroll;
}

.userRow:nth-of-type(2n+1) {
  background: #dcdcdc;
}

.userRow:hover {
  background: #ccd;
}

.userList th {
  text-align: center;
  padding: 0 10px;
  font-size: 14px;
}

.userList th a {
  color: #777;
}

.userList td {
  border: 1px solid #bbb;
  padding: 4px;
  text-align: center;
}

.userList table {
  border-collapse: collapse;
}

.sortDirection  {
  color: orange;
}

.userButtons {
  min-width: 180px;
  text-align: center;
}

.userButtons div {
  border: 1px solid pink;
  margin: 0 auto;
}

.changeRole {
  float: left;
  width: 48%;
}

.deleteUser, .confirmDeleteUser {
  float: right;
  width: 48%;
}


//print info
@media print {
  .printPanel {page-break-after: always;}
}

.printField {
  font-size: 20px;
  font-weight: bold;
}

.printPanelPaper:not(:last-of-type) {
  border-bottom: 1px solid #aaa;
  padding: 3px 0;
}

.backButton, .showConferences {
  background: #F15A24;
  border: none;
}

.showConferences:hover, .backButton:hover {
  background: #B9461D;
}

.newConference {
  //background: #B9461D
  background: #000;
}

.newConference:hover {
  //background: #B9461D
  background: #555;
}

@media only screen and (max-width: 480px) {
  .outputPanelAttribute {
    padding: 2px 0;
    font-size: 12px;
  }

  .attributeLabel {
    font-size: 12px;
  }

  .printField {
    font-size: 14px;
  }

  .printFieldH3 {
    font-size: 14px;
    margin-left: 25px;
  }
}

.paperListHeader {
  margin-left: 25px;
}

.downloadButton {
  margin-top: 3px;
}



