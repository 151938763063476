
// Body
$body-bg: #f5f8fa;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #3097D1;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;

// Typography
$font-family-sans-serif: "Raleway", sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #555;

// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;

// Section backgrounds
$heading-background: #ccc;
$light-background: #eee;
$dialog-background: #cbcbcb;
$tag-bg: #eee;
$author-paper-bg1: #cecece;
$paper-attribute-bg: #cecece;
$darker-bg: #ccc;
$count-bg: #aad;
$count-alt-bg: #e55;

// Section for large buttons
$lg-button-height: 45px;
$lg-button-font-size: 1.2em;

//Borders
$paper-border: #acacac;
$dialog-border: #666;
$light-border: #ccc;
$dark-border: #333;
